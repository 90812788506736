import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import styles from '../templateStyles/tag.module.css'

import Layout from '../components/Layout'
import PostGenerator from '../components/PostGenerator/PostGenerator'
import SEO from '../components/SEO/SEO'
class TagRoute extends React.Component {
  state = {
    latest: true,
    oldest: false,
  }

  render() {
    const tag = this.props.pageContext.name

    return (
      <Layout>
        <SEO title={tag} />
        <section className={styles.container}>
          <div className={styles.page}>
            <div className={styles.content}>
              <div className={styles.tagContainer}>
                <h1 className={styles.tagHeader}>{tag.toUpperCase()}</h1>
              </div>
              <PostGenerator
                props={this.props}
                state={this.state}
                viewData={this.props.data.PageViews}
              />
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default TagRoute

export const tagPageQuery = graphql`
  query TagPage($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    PageViews: allPageViews {
      edges {
        node {
          id
          totalCount
        }
      }
    }
    Latest: allWordpressPost(
      filter: { tags: { elemMatch: { slug: { eq: $slug } } } }
      sort: { fields: [date], order: DESC }
    ) {
      edges {
        node {
          id
          title
          content
          acf {
            excerpt
          }
          slug
          date(formatString: "MMM D, YYYY")
          datetime: date
          categories {
            name
            id
          }
          author {
            name
          }
          featured_media {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1600, maxHeight: 1200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    Oldest: allWordpressPost(
      filter: { tags: { elemMatch: { slug: { eq: $slug } } } }
      sort: { fields: [date], order: ASC }
    ) {
      edges {
        node {
          title
          acf {
            excerpt
          }
          slug
          date(formatString: "MMM D, YYYY")
          datetime: date
          categories {
            name
            id
          }
          author {
            name
          }
          featured_media {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1600, maxHeight: 1200) {
                  ...GatsbyImageSharpFluid
                }
                fixed(width: 600, height: 400) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
  }
`
